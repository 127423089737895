.App {
  overflow-x: hidden;
  min-height: 100vh;
}

ul li:hover {
  cursor: pointer; /* Change cursor to pointer on hover */
  text-decoration: underline;
}

.map-link {
  text-decoration: underline;
}
.map-link:hover {
  cursor: pointer; /* Change cursor to pointer on hover */
  text-decoration: underline;
}

.container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.App-header {
  background-color: #FFFFFF;
  display: block;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.App-main {
  height: 100%;
  text-align: center;
  display: block;
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}


.App-footer {
  background-color: #F5ECEF;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 100vw; /* Ensure footer is always visible */
  overflow-x: auto; /* Enable horizontal scrolling */
}

.footer-div {
  margin-top: auto;
}

.footer-text {
  display: block;
  word-wrap: break-word;
  max-width: 100%;
}

.App-header{
  width: 100%;
  display: block;
}

.logo {
  width: 30%;
  display: inline-block;
  text-align: left;
}
.logo-name {
  color: #845a5d;
  font-size: 80%;
  font-style: italic;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-weight: 400;
  padding-left: 40px;
}

.logo-name:hover {
  cursor: pointer; /* Change cursor to pointer on hover */
}

.nav {
  width: 65%;
  display: inline-block;
  text-align: end;
  margin-right: 10px;
}

.navbar {
  margin: 0;
  padding: 0;
}

.navbar li {
  display: inline;
  margin-right: 20px; /* Adjust the spacing between links */
  color: #845a5d;
  font-size: 80%;
  font-style: italic;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-weight: 400;
}

.navbar-wrapper {
  display: none;
}


.menu {
  width: 100%;
  background-color: #ffe2ee;
  padding-top: 5px;
  padding-right: 10px;
}

.header-img {
  background-color: #FFE6EE;
}

.header-pic {
  background: url("../public/background.jpg");
  background-repeat: inherit;
}

.header-pic2 {
  padding-top: 25px;
  text-align: center; /* Center the images horizontally */
}

.header-img-brno {
  width: 100%;
  max-height: 861px;
  max-width: 1533px;
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-img2 {
  display: none;
  width: 120%;
  height: 120%;
  max-height: 560px;
  max-width: 1050px;
  margin: 0 auto;
}

.bday-img {
  width:80%;
  height:80%;
  max-width:420px;
  max-height:746px;
}

.wedding-img {
  width:80%;
  height:80%;
  max-width:504px;
  max-height:895px;
}

.banska-img {
  width: 80%;
  height:80%;
  max-width:470px;
  max-height:350px;
}

.ring-img{
  max-width:320px;
  max-height:426px;
}

.flower-img{
  max-width:320px;
  max-height:426px;
}
.counter {
  padding-bottom: 5px;
  background-color: #ffe2ee;
}

.counter h1 {
  margin-top: 0;
}

.counter-title {
  color: #b17a7d;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-style: normal;
  font-size: 2.5rem;
  font-weight: 400;
  display: inline-block;
  margin:0;
  padding-bottom: 10px;
}

.counter-title-map{
  color: #b17a7d;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  margin:0;
  padding-bottom: 10px;
  padding-top: 50px;
}
.counter-text {
  color: #9d9d9d;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-style: normal;
  font-size: 150%;
  font-weight: 400;
}

.title-line {
  width: 50px; /* Width of the line */
  height: 2px; /* Height/thickness of the line */
  background-color: #b17a7d; /* Color of the line */
  margin: 0 auto;
}

.title-line-mid {
  display: none;
}

.qr-title {
  color: #9d9d9d;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-style: normal;
  font-weight: 400;
}

.counter-numbers {
  color: #845a5d;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.quote{
  text-align: center;
  margin: auto;
  min-width: 20px;
  padding-top: 50px;
  display: block;
}

.quote-text{
  color: #9d9d9d;
  font-size: 2em;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-style: normal;
  font-weight: 300;
  margin: 0 0 0 0;
  margin-block-start: 0.2em;
}

.quote-text-vm {
  text-align: center;
  margin-left: 200px;
}

.pictures{
  background-color: #FFFFFF;
  padding: 50px 6% 50px 10%;
}

.pictures-mobile {
  display: none;
}

.when{
  margin-top: 25px;
  background-color: #f9eaef;
  align-items: flex-start;
  padding-bottom: 25px;
}

.title{
  display: inline-block;
  width: 100%;
  text-align: center;
  align-items: flex-start;
  word-break: break-word;
  padding-top: 20px;
}

.text{
  display: inline-block;
  color: #9d9d9d;
  font-size: 1.2rem;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-weight: 300;
  margin-block-start: 0.2em;
  margin: 0 auto;
  text-align: center;
  padding-left: 20%;
}

.where{
  background-color: #f9eaef;
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px;
}

.where-map {
  width: 50%;
  font-style: italic;
  align-items: flex-start;
  padding-bottom: 20px;
  padding-top: 50px;
  order: 1
}

.map-content {
    display: flex; /* Use flexbox to align children */
}

.map{
  width: 100%;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  order: 1;
  padding-bottom: 20px;
}

.maps {
  width: 50%;
  padding-top: 50px;
}
.map-img-1 {
  width: 90%;
  height: 300px;
  border: 0;
}

.map-img-2 {
  width: 90%;
  height: 300px;
  border: 0;
}

.map-text{
  text-align: center;
  display: inline-block;
  width: 50%;
  color: #9d9d9d;
  font-size: 1.2rem;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-weight: 300;
  margin-block-start: 0.2em;
  order: 2;
  padding-right: 10%;
}

.map2{
  width: 100%;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  order: 1;
}

.when-title{
  color: #b17a7d;
  font-weight: 400;
  font-size: 1.5rem;
}

.dio-link{
  text-decoration: none;
  color: inherit;
}

.dio-link:hover {
  text-decoration: underline;
}
.gift {
  padding-top: 20px;
  padding-bottom: 50px;
}

.feedback {
  display: block;
  text-align: center;
  padding: 25px;
}

.feedback-title {
  color: #b17a7d;
  font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
  font-style: normal;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

.fbleft {
  display: inline-block;
  width: 50%;
  text-align: center;
  align-items: flex-start;
  word-break: break-word;
  padding-left: 10%;
}

.fbright {
  width: 50%;
  display: inline-block;
  margin: 0 0 0 0;
  margin-block-start: 0.2em;
  padding-left: 200px;
  text-align: left;
}

.line-area{
  display: block;
  width: 70%;
  height: 5px;
  background: #262626;
}

.timeline-area{
  padding-top: 100px;
  padding-left: 100px;
  height: 100%;
  display: none;
}

.single-item {
  width: 6%;
  display: inline-block;
  height: 100%;
  margin-right: 70px;
  transition: wid;
}

.single-item > span {
  height: 32px;
  display: block;
  font-weight: bold;
  margin-top: -8px;
  width: 100%;
  cursor: pointer;
}

.single-item > span:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background: #000;
  margin: 0 auto;
  border-radius: 50%;
}

.img-area {
  display: none;
}
.img-area a {
  display: block;
  width: 100%;
  height: 100%;
}
.img-area a span {
  width: 250%;
  margin-left: -72%;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.img-area a span:after {
  margin-left: 5px;
}
.img-area.active{
  width: 10%;
}

.moneyCry {
  width: 60%;
  height: 60%;
  max-width: 400px;
}

.bottompng {
  width: 60%;
  height: 60%;
  max-width: 600px;
  vertical-align: bottom;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 31%;
  max-width: 31%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}


@media screen and (max-width: 768px) {

  .logo {
    width: 50%;
  }

  .nav {
    width: 50%;
  }

  .quote-text-vm {
    margin-left: 0;
  }

  .quote-text {
    font-size: 1.2em;
  }

  .counter-title {
    font-size: 1.8em;
  }

  .navbar-wrapper {
    width: 45%;
    text-align: end;
    display: inline-block;

  }

  .nav {
    display: none;
  }

  .navbar-wrapper {
    position: relative; /* Make the wrapper a positioning context for the dropdown */
  }

  .menu-button {
    /* Optional: Adjust button styling */
  }

  .dropdown-menu {
    position: absolute;
    top: 100%; /* Position the menu below the button */
    right: 0; /* Align the right edge of the menu with the right edge of the button */
    background-color: #FFDBE9; /* Optional: Menu background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Menu box shadow */
    display: none;
  }

  .dropdown-menu.open {
    display: block;
  }

  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: auto;
    text-align: center;
  }

  .dropdown-menu ul li {
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Add bottom border to menu items */
    text-decoration-color: #845a5d;
  }

  .dropdown-menu ul li:last-child {
    border-bottom: none; /* Remove bottom border from last menu item */
  }

  .dropdown-menu ul li a {
    text-decoration: none;
    color: #845a5d;
    font-size: 80%;
    font-style: italic;
    font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
    font-weight: 400;
  }

  .menu-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .line {
    display: block;
    background-color: #845a5d;
    width: 20px;
    height: 2px;
    margin-bottom: 4px; /* Adjust spacing between lines */
  }

  .when {
    background-color: #FFE6EE;
    padding-bottom: 10px;
    display: block;
    text-align: center;
  }

  .when {
    background-color: #FFE6EE;
    padding-bottom: 10px;
    display: block;
    text-align: center;
  }


  .text {
    padding-top: 15px;
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }

  .title{
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-left: 0;
  }

  .where{
    background-color: #FFE6EE;
    display: block;
    text-align: center;
  }

  .where2{
    background-color: #FFE6EE;
    display: block;
    text-align: center;
  }

  .map{
    padding-top: 0px;
    padding-left: 0px;
    text-align: center;
    align-items: flex-start;
    order: 1;
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .maps {
    padding-top: 0;
    width: 100%;
  }

  .map2{
    padding-left: 0;
    text-align: center;
    width: 100%;
    display: block;
    margin: 0 auto;
    align-items: flex-start;
    order: 1;
  }

  .map-text{
    padding-left: 0;
    text-align: center;
    display: inline-block;
    color: #9d9d9d;
    font-size: 1.2rem;
    font-family: PT Serif, PT-Serif-Fallback, Noto Color Emoji, serif;
    font-weight: 300;
    margin: 0 auto;
    width: 100%;
    margin-block-start: 0.2em;
    order: 2;
    padding-right: 0;
    padding-top: 0;
  }

  .where-map {
    width: 100%;
  }

  .map-img-1 {
    width: 90%;
    height: 300px;
    border: 0;
  }

  .map-img-2 {
    width: 90%;
    height: 300px;
    border: 0;
  }

  .feedback{
    display: block;
    text-align: center;
  }

  .pictures{
    display: none;
  }

  .pictures-mobile{
    display: block;
    background-color: #FFFFFF;
    padding: 50px;
  }

  .title-line-mid {
    width: 50px; /* Width of the line */
    height: 2px; /* Height/thickness of the line */
    background-color: #b17a7d; /* Color of the line */
    margin: 0 auto;
    display: block;
  }

  .map-content {
    display: block;
  }
}